import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {Button, Spin} from 'antd';

import * as AppContext from '../../AppContext';
import ActivityTable from '../../Components/Activity.Table';
import Footer from './Footer';

function LandingPage(props) {
  const app = React.useContext(AppContext.Context);
  const [records, setRecords] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const fetchActivityList = React.useCallback(async () => {
    setLoading(true);
    try {
      let records = await app.actions.fetchActivityList();
      records = records.filter((record) => record.status !== 'draft');
      setRecords(records);
    } catch (ex) {
      console.warn('fetch activity list error');
    }

    setLoading(false);
  }, [app.actions]);

  React.useEffect(() => {
    fetchActivityList();
  }, [fetchActivityList]);

  return (
    <Wrapper>
      <div className="banner">
        <img
          alt="logo"
          src={'/images/logo.png'}
          style={{width: 250, height: 67, marginBottom: 22}}
        />
        <h2
          style={{
            textAlign: 'center',
            fontSize: 17,
            color: '#4A4A4A',
            margin: 10,
            marginBottom: 70,
          }}>
          專業出眾的專案團隊，深入多元的會展服務
        </h2>

        <div className="action-bar">
          <Button
            type="primary"
            onClick={() => navigate('/login')}
            style={{width: 250, minHeight: 40}}>
            登入
          </Button>
        </div>
      </div>

      <div className="content">
        <h2 style={{marginBottom: 40}}>最新活動</h2>
        <div className="activity-table">
          {!records ? <Spin /> : <ActivityTable records={records} />}
        </div>
      </div>

      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;

  & > .banner {
    background-image: url('/images/public-banner.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    width: 100%;
    height: 600px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > .action-bar {
      display: flex;
    }
  }

  & > .content {
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > .activity-table {
      max-width: 1000px;
      min-height: 300px;
      margin: 0 auto;
    }
  }

  & > .footer {
    box-shadow: 0 -5px 20px 0 rgba(0,0,0,0.05);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > .logo-container {
      padding: 25px;
    }

    & > .contact {
      border-top: 1px solid #eaeaea;
      padding: 25px;
      width: 100%;

      & > .container {
        max-width: 1440px;
        width: 100%;
  
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        margin:  0 auto;

      & > .content {
        min-width: 150px;


        & > .item {
          margin-bottom: 15px;
          display: flex;
          align-items: center;

          & > img {
            margin-right: 26px;
            width: 26px;
            height: 26px;
          }

          & > div, span {
            color: #4a4a4a;
          }
        }
        }
      }
    }

    & > .copyright {
      border-top: 1px solid #eaeaea;
      padding: 25px;
      width: 100%;
      text-align: center;
      line-height: 22px;
      color: #4a4a4a;
    }
}
  }
`;

export default LandingPage;
