import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {Button, Spin} from 'antd';
import * as AppContext from '../../AppContext';

function Footer(props) {
  return (
    <Wrapper>
      <div className="logo-container">
        <img
          alt="logo"
          src={'/images/public-icon-08.png'}
          style={{width: 200, height: 46}}
        />
      </div>

      <div className="contact">
        <div className="container">
          <div className="content">
            <div className="item">
              <img alt="icon04" src="/images/public-icon-04.png" />
              <span>Tel：+886-2-2720-1610</span>
            </div>
            <div className="item">
              <img alt="icon05" src="/images/public-icon-06.png" />
              <span>企業聯絡窗口：hwhgroup2004@gmail.com</span>
            </div>
            <div className="item">
              <img
                alt="icon07"
                src="/images/public-icon-07.png"
                style={{width: 20, height: 28}}
              />
              <div>
                <div>110 台北市信義區信義路五段5號5樓F區23-26室</div>
                <div>
                  Rm. 5F 23-26, No. 5, Sec. 5, Xinyi Rd., Xinyi Dist., Taipei
                  City 110, Taiwan (R.O.C.)
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="item">
              <img alt="icon05" src="/images/public-icon-05.png" />
              <span>Fax：+886-2-2720-5397</span>
            </div>
            <div className="item">
              <img alt="icon06" src="/images/public-icon-06.png" />
              <span>工讀資訊聯絡窗口：bfhrs5b32@gmail.com</span>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        COPYRIGHT © 2021 HWH EXECUTIVE CONSULTANT LIMITED ALL RIGHTS RESERVED.
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-shadow: 0 -5px 20px 0 rgba(0, 0, 0, 0.05);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .logo-container {
    padding: 25px;
  }

  & > .contact {
    border-top: 1px solid #eaeaea;
    padding: 25px;
    width: 100%;

    & > .container {
      max-width: 1440px;
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: 0 auto;

      & > .content {
        min-width: 150px;

        & > .item {
          margin-bottom: 15px;
          display: flex;
          align-items: center;

          & > img {
            margin-right: 26px;
            width: 20px;
            height: 20px;
          }

          & > div,
          span {
            color: #4a4a4a;
          }
        }
      }
    }
  }

  & > .copyright {
    border-top: 1px solid #eaeaea;
    padding: 25px;
    width: 100%;
    text-align: center;
    line-height: 22px;
    font-size: 12px;
    color: #4a4a4a;
  }
`;

export default Footer;
